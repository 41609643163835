.u-squeeze {
  margin: 0 auto;
  
  &--xs {
    max-width: rem-calc(600);
  }
  &--s {
    max-width: rem-calc(750);
  }
  &--m {
    max-width: rem-calc(900);
  }
  &--l {
    max-width: rem-calc(1036);
  }
  &--xl {
    max-width: rem-calc(1136);
  }
  &--xxl {
    max-width: rem-calc(1236);
  }
  &--none {
    max-width: 100%;
  }

  // position
  &--left {
    margin: 0 auto 0 0;
  }

  &--right {
    margin: 0 0 0 auto;
  }

  &--center {
    margin: 0 auto;
  }
}
