.v-install-script {
    &__background {
        max-height: 375px;
        height: 375px;
        background-image: url('../../images/Install-script-background.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100% 45%;
        transform: scaleX(-1);
    }

    &__breadcrumb-container {
        margin-bottom: 47px;
    }

    &__content-wrapper {
        padding: 25px 61px 51px 61px;
    }

    &__content-left {
        width: 489px;
    }

    &__content-right {
        margin-left: rem-calc(85);
    }

    &__content-title {
        font-size: rem-calc(30);
        font-weight: 500;
        margin-bottom: rem-calc(6);
        margin-top: rem-calc(0);
        
    }

    &__content-title-secondary {
        font-size: rem-calc(21);
        font-weight: 500;
        margin-bottom: rem-calc(20);
        margin-top: rem-calc(10);
    }

    &__content-text {
        font-size: 16px;
        margin-bottom: rem-calc(30);
    }

    &__steps {
        max-width: 247px;
    }

    &__code-block {
      margin-bottom: 25px;
    }
}