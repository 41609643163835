// animation: sk-stretchdelay 1.2s infinite ease-in-out;

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }  
  20% {
    transform: scaleY(1.0);
  }
}
