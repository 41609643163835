/**
 * Tables
 *
 * @since 1.0.0
 */

table {
  border-collapse: collapse;
  margin: 0 0 1.5rem;
  width: 100%;
}

thead th {
  border-bottom: 2px solid $light-gray;
  padding-bottom: .75rem;
}

th {
  padding: .75rem;
  text-align: left;
  font-weight: bold;
}

tr {
  border-bottom: 1px solid $light-gray;
}

td {
  padding: .75rem;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}
