/**
 * Typography
 *
 * @since 1.0.0
 */

/**
 * HTML base
 */
 html {
  font-family: $primary-font-family;
  font-size: 16px;
  color: $primary-font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  font-size: rem-calc(18);
}

/**
 * Paragraph
 */
p,
.p {
  line-height: 1.9;
  font-size: 1.125rem;
  // margin-bottom: 1rem;

  .u-text-small & {
    font-size: 1rem;
    color: $gray;
    line-height: 2;
  }

  a:not(.c-button) {
    transition: 0.3s;
    font-weight: 500;
    text-decoration: underline;
    color: inherit;

    &:hover {
      color: $white;
      background: $almost-black;
      text-decoration: none;
    }
  }

  strong {
    font-weight: 500;
  }
}

/**
 * Headings
 */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $heading-font-family;
  line-height: 1.2;
  margin-bottom: 1rem;

  span {
    font-family: $heading-font-family;
    font-weight: 300;
  }

  // span {
  //   font-family: $secondary-font-family;
  //   font-style: italic;
  // }
}

/**
 * H1
 */
h1,
.h1 {
  font-size: rem-calc(35);
  font-weight: 400;

  @include breakpoint(medium) {
    font-size: rem-calc(45);
  }
  @include breakpoint(large) {
    font-size: rem-calc(56);
  }
}

/**
 * H2
 */
h2,
.h2 {
  font-size: rem-calc(36);
  font-weight: 400;

  @include breakpoint(medium) {
    font-size: rem-calc(40);
  }
  @include breakpoint(large) {
    font-size: rem-calc(52);
  }
}

/**
 * H3
 */
h3,
.h3 {
  font-size: rem-calc(26);
  font-weight: 400;
  line-height: 1.3;

  @include breakpoint(medium) {
    font-size: rem-calc(40);
  }
}

/**
 * H4
 */
h4,
.h4 {
  font-size: rem-calc(22);
  font-weight: 400;
  line-height: 1.3;

  @include breakpoint(medium) {
    font-size: rem-calc(30);
  }
}

/**
 * H5
 */
h5,
.h5 {
  font-size: rem-calc(20);

  @include breakpoint(medium) {
    font-size: rem-calc(24);
  }
}

/**
 * H6
 */
h6,
.h6 {
  color: $darker-gray-alt;
  font-size: rem-calc(14);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;

  @include breakpoint(medium) {
    font-size: rem-calc(16);
  }

  .body-container--landingspage & {
    color: $almost-black;
    font-size: rem-calc(14);
  }
}

/**
 * Anchor
 */
a {
  @include transition();
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:not([class]) {
    // All A tags without a class
    text-decoration: underline;

    // &:hover {
    //   background-color: $almost-black;
    //   color: $white;
    //   text-decoration: none;
    // }
  }

  .c-icon {
    // fill: inherit;
  }
}

/**
 * Bold & strong
 */
b,
strong {
  font-weight: 700;
  line-height: inherit;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

/**
 * Mark, Ins & Pre
 */
mark,
ins {
  background-color: lighten($theme-color-1, 80);
  text-decoration: none;
}

code {
  font-family: monospace;
  font-size: 90%;
}

pre {
  font-family: monospace;
  font-size: 90%;
  background: lighten($gray, 20);
  line-height: 1.2;
  overflow: hidden;
}

/**
 * small & big
 */

small {
  font-size: 75%;
}

big {
  font-size: 125%;
}

/**
 * Blockquote & Q
 */
blockquote {
  margin: 1.5rem 0;
  font-size: rem-calc(18);
  font-style: italic;
}

q {
  font-weight: bold;

  &::before,
  &::after {
    content: '\0022';
  }
}

/**
 * HR
 */
hr {
  background-color: $light-blue;
  border: 0;
  height: 1px;
  width: 100%;
  margin: 1.5rem 0;
}
