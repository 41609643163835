.c-card {
  display: flex;
  max-width: calc(33.33% - 24px);
  flex-direction: column;
  margin-right: 36px;

  &:nth-child(3n) {
    margin-right: 0px;
  }

  &-icon {
    margin-bottom: rem-calc(21);
    height: 40px;
  }

  &__content {
    &-step {
      font-size: 12px;
      font-weight: 500px;
      color: #666666;
      text-transform: uppercase;
      margin-bottom: 7px;
    }

    &-title {
      margin-bottom: rem-calc(21);
      font-size: rem-calc(20);
      font-weight: 500;
    }

    &-text {
      margin-bottom: rem-calc(21);
      font-size: rem-calc(16);
      line-height: 1.75;
      font-weight: 300;
    }

    &-button {
      margin-top: auto;
    }
  }
}