/**
 * Gradient
 *
 * @since 1.0.0
 */

$gradient: linear-gradient($theme-color-1, $theme-color-2);
$gradient-2: linear-gradient($theme-color-1, $theme-color-3);
$gradient-3: linear-gradient($white, $theme-color-5);

$gradient-bottom: linear-gradient(to bottom, $theme-color-1, $theme-color-2);
$gradient-2-bottom: linear-gradient(to bottom, $theme-color-1, $theme-color-3);
$gradient-3-bottom: linear-gradient(to bottom, $white, $theme-color-5);

$gradient-top: linear-gradient(to top, $theme-color-1, $theme-color-2);
$gradient-2-top: linear-gradient(to top, $theme-color-1, $theme-color-3);
$gradient-3-top: linear-gradient(to top, $white, $theme-color-5);
