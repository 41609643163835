.c-breadcrumb {
  &__item {
      font-weight: 300;

      &--home {
          font-weight: 300;
      }

      &--bold {
          font-weight: 500;
      }
  }

  &__separator-icon {
      margin-left: 14px;
      margin-right: 14px;
  }
}
