/**
 * Background object
 *
 * @since 1.0.0
 */

.o-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
