.v-properties {
  &__content-wrapper {
      padding: 76px 55px;
  }

  &__content-container {
      margin-top: 60px;

      &__left {
          width: calc(41% - 37px);
      }

      &__right {
          width: calc(59% - 37px);
          margin-left: auto;
      }
  }

  &__titles-container {
    width: 100%;
  }

  &__hubspot-property-title-container {
    width: calc(50% - 20px);
  }

  &__company-info-property-title-container {
    width: calc(50% - 20px);
    margin-left: auto;
  }

  &__hubspot-properties-container {
    width: (calc(50% - 20px));
  }

  &__company-info-properties-container {
    width: (calc(50% - 20px));
    margin-left: auto;
  }

  &__hubspot-properties-title {
    margin-top: 0px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: rem-calc(27);
    text-transform: uppercase;
    color: #666666;
  }

  &__company-info-properties-title {
    margin-top: 0px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: rem-calc(27);
    text-transform: uppercase;
    color: #666666;
  }

  &__property-pair {
    margin-bottom: 20px;
  }

  &__hubspot-property {
    width: calc(50%);
    font-size: 15px;

    &-label {
      width: calc(100% - 25px);
      padding-left: 5px;
    }
  }

  &__company-info-property {
    width: calc(50% - 20px);
    margin-left: auto;
    font-size: 15px;
  }

  &__title {
      margin-top: 0px;
      font-size: rem-calc(30);
      font-weight: 500;
      margin-bottom: rem-calc(25);
  }

  &__text {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: rem-calc(25);
  }

  &__line {
      width: 100%;
      height: 1px;
      background-color: $light-blue-gray-alt;
      margin-top: rem-calc(0);
      margin-bottom: rem-calc(24);
  }

  &__delete-unselected-container {
    margin-top: 10px;
  }

  &__delete-unselected-checkbox {
      margin-bottom: 10px;
  }

  &__delete-unselected-title {
    font-size: 16px;
    font-weight: 300;
    margin-left: 5px;
  }
}