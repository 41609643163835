/**
 * (Un)ordered Lists
 *
 * @since 1.0.0
 */

ul,
ol {
  list-style-position: inside;
  margin: 0 0 1.5rem 1.5rem;
  padding: 0;
  line-height: 1.4;
}

ul {
  list-style: disc;

  &.arrows {
    list-style-type: none;
    margin-left: 0;
    font-size: rem-calc(20);
    font-weight: 500;

    & > li {
      position: relative;
      margin-left: 0;
      padding-left: 2.5rem;
      background-image: url('https://www.bureaubright.nl/hubfs/bb_theme_images/arrow-right.svg');
      background-position: left 6px;
      background-repeat: no-repeat;
      background-size: 24px 13px;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include breakpoint(xmlarge) {
      font-size: rem-calc(32);

      & > li {
        padding-left: 70px;
        background-position: left 8px;
        background-size: 46px 24px;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

ol {
  list-style: decimal;
}

li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0; //reset
  margin-top: 0.5rem;
  margin-left: 1.5rem;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5rem 1.5rem;
}
