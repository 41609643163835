.c-default-header {
    background-color: $white;
    box-shadow: $header-shadow;

    &__container {
        padding: rem-calc(16) 0 rem-calc(12);
    }

    &__logo {
        width: 100%;
        max-width: rem-calc(88);
    }

    &__profile-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: rem-calc(7);
    }

    &__profile-icon {
        height: rem-calc(40);
        width: rem-calc(40);
        margin-left: rem-calc(18);
    }
}
