.c-install-header {
  background-color: $white;

  &__container {
      display: flex;
      justify-content: center;
      padding: rem-calc(40) 0 rem-calc(40);
  }

  &__logo {
      width: 100%;
      max-width: rem-calc(110);
  }
}
