.c-table-row {
    &__content-container {
        width: calc(100% - 140px);
    }

    &__line {
        width: 100%;
        height: 1px;
        background-color: $light-blue-gray-alt;
        margin-top: rem-calc(24);
        margin-bottom: rem-calc(24);
    }

    &__title-container {
        width: calc(50% - 80px);
    }

    &__value-container {
        margin-right: auto;
    }

    &__title {
        font-size: 16px;
        font-weight: 600;
        margin-right: 48px;
    }

    &__value {
        font-size: 16px;
        font-weight: 300;
    }

    &__button-container {
        margin-left: auto;
    }

    &__input {
        @include transition(all);
        z-index: 1;
        position: relative;
        -webkit-appearance: none;
        appearance: none;
        font-family: $primary-font-family;
        font-size: rem-calc(16);
        background-color: $white;
        color: $input-color;
        outline: 0;
        border: none;
        box-shadow: none;
        font-weight: 300;
        padding: 0;
        margin-bottom: 2px;
    }
}