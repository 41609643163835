.v-landingpage {
  min-height: 100vh;

  &__header {
    &-container {
      width: 100%;
      padding-top: rem-calc(24);
      padding-bottom: rem-calc(48);
    }

    &__logo-left {
        width: 100%;
        max-width: rem-calc(110);
    }
  }

  &__content {
    &-left {
      width: 100%;

      @include breakpoint(large) {
        width: 50%;
      }

      &-inner {
        max-width: 618px;
        margin-left: auto;

        &-spacing {
          padding-right: rem-calc(110);
        }
      }
    }

    &__payoff {
      font-size: rem-calc(14);
      font-weight: 500;
      text-transform: uppercase;
    }

    &__title {
      max-width: rem-calc(481);
      font-weight: 500;
      margin-top: rem-calc(20);
      margin-bottom: rem-calc(32);
  
      @include breakpoint(large) {
        font-size: rem-calc(50);
      }
    }

    &__text {
      font-weight: 300;
      margin-bottom: rem-calc(46)
    }

    &__form-bar {
      margin-bottom: rem-calc(24);

      &__remember {
        gap: rem-calc(9);

        &-title {
          font-size: rem-calc(16);
          font-weight: 300;
        }
      }

      &__password {
        font-size: rem-calc(16);
        font-weight: 500;
        text-decoration: underline;
      }
    }

    &__no-account {
        margin-top: rem-calc(15);
  
        &__title {
          margin-top: 0;
          margin-bottom: 0px;
          font-size: rem-calc(16);
          font-weight: 300;
  
        &-secondary {
          font-size: rem-calc(16);
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }

      &-right {
        width: 100%;

        @include breakpoint(large) {
          width: 50%;
        }

        &-inner {
          max-width: 618px;
          margin-right: auto;

        &-spacing {
          padding-left: rem-calc(110);
        }
      }
    }

      &__image-preview {
        &__container {
          margin-bottom: rem-calc(47);
        }

        &__image {
          height: 100%;
          width: 700px;

          @include breakpoint(xxlarge) {
            height: 100%;
            width: 700px;
          }
        }

      }

      &__logo-row {
        width: rem-calc(618);
      }
    }
  }