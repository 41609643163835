.c-hubspot-sticker {
    &__sticker-icon {
        width: 100%;
        max-width: rem-calc(68);
    }
    
    &__content-container {
        flex-direction: column;
        margin-left: rem-calc(15);
    }

    &__rating {
        margin-right: auto;
        margin-bottom: rem-calc(10);

        &-title {
            font-size: rem-calc(12);
            margin-top: 2px;
        }
    }

    &__star-icon {
        width: 100%;
        max-width: rem-calc(12);
        margin-right: 0.3rem;
    }

    &__button {
        &-title {
            font-size: rem-calc(12);
            text-decoration: underline;
        }
    }
}
