.v-user-create {
    &__return-container {
        padding: 48px 55px 0px 55px;
    }

    &__content-wrapper {
        padding: 0px 250px 48px 250px;
    }

    &__profile-icon {
        height: rem-calc(150);
        width: rem-calc(150);
    }

    &__personal-information {
        margin-left: 30px;

        &__name {
            font-size: 30px;
            font-weight: 500;
            margin-top: rem-calc(30);
            margin-bottom: rem-calc(9);
        }

        &__email {
            font-size: 16px;
            font-weight: 300;
        }
    }

    &__content-container__inner {
        &__title {
            margin-bottom: rem-calc(50);
            margin-top: rem-calc(50);
            font-size: 30px;
            font-weight: 500;
        }

        &__text {
            font-size: 16px;
            font-weight: 300px;
            max-width: rem-calc(675);
        }
    }

    &__checkbox-container {
        flex-direction: column;
        
        & label:last-child {
            margin-bottom: rem-calc(40);
        }
    }

    &__checkbox-label {
        margin-bottom: rem-calc(10);
    }

    &__checkbox-text {
        margin-left: 10px;
    }
}