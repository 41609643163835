.v-activate {
    &__content-wrapper {
        margin-top: 25px;
        flex-direction: column;
        justify-content: center;
    }

    &__content-container {
        width: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    &__content-input {
        justify-content: center;
        margin-bottom: 25px;
    }

    &__content-paragraph {
        font-size: 16px;
        font-weight: 300;
        margin-top: 5px;
        margin-bottom: 20px;
    }
}
