.c-user-detail-row {
    &__content-container {
        width: 100%;
    }

    &__line {
        width: 100%;
        height: 1px;
        background-color: $light-blue-gray-alt;
        margin-top: rem-calc(24);
        margin-bottom: rem-calc(24);
    }

    &__label-container {
        width: rem-calc(150);
    }

    &__value-container {
        margin-left: rem-calc(50);
    }

    &__label {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
    }

    &__value {
        display: inline-block;
        font-size: 16px;
        font-weight: 300;
        margin-left: rem-calc(5);
    }

    &__button-container {
        margin-left: auto;
    }

    &__checkbox-container {
        flex-direction: column;

        & label:first-child {
            margin-bottom: rem-calc(15);
        }
    }

    &__cancel-button {
        margin-left: rem-calc(15);
    }
}
