/**
 * Colors
 *
 * @since 1.0.0
 */

$black: #000;
$almost-black: #212121;
$darker-gray: #292929;
$white: #fff;
$white-gray: #f5f5f5;
$light-gray: #bfbfbf;
$light-blue: #f5f7fa;
$light-blue-gray: #d6dde6;
$light-blue-gray-alt: #E3EBF1;
$blue-gray: #c1c9d3;
$text-gray: #6e6e6e;
$icon-gray: #666666;
$footer-gray: #b5b5b5;
$disclaimer: #909090;
$gray: #7b7b7b;
$dark-gray: #3f3d56;
$darker-gray-alt: #737576;
$error: #df840e;
$success: #98bd78;
$yellow: #ffd736;
$star: #F5C26B;
$gradient-color-1: #f59f1c;
$gradient-color-2: #fda15f;
$gradient-color-3: #ffcc2a;
$gradient-color-4: #ef9d22;
$gradient-color-5: #fed736;
$primary-font-color: $almost-black;
$see-through: rgba(33, 33, 33, 0.6);

$gradient-45deg: linear-gradient(45deg, $gradient-color-1 0%, $gradient-color-2 50%, $gradient-color-3 100%);
$gradient-65deg: linear-gradient(65deg, $gradient-color-1 0%, $gradient-color-2 50%, $gradient-color-3 100%);
$gradient-65deg-reversed: linear-gradient(65deg, $gradient-color-3 0%, $gradient-color-2 50%, $gradient-color-1 100%);
$gradient-180deg: linear-gradient(180deg, $yellow, $gradient-color-2);
$gradient-button: linear-gradient(15deg, $gradient-color-4 0%, $gradient-color-2 50%, $gradient-color-5 100%);

// Theme colors
$theme-color-1: #ffd736; 
$theme-color-2: #f59f1c; 
$theme-color-3: #fda15f; 
$theme-color-4: #2b005d; 
$theme-color-5: #ffcc2a; 
$theme-color-6: #f3f2ff; 
$theme-color-7: #ffc400;

// Extra colors, not needed
$green: #60a700;
$orange: #e79632;

$box-shadow: 0px 3px 30px rgba(#99a9be, 0.2);
$box-shadow-dark: 0px 30px 60px rgba($black, 0.16);

// Forms
$input-border: $light-gray;
$input-background: transparent;
$input-color: $almost-black;
$input-disabled: $light-gray;
$input-focus: $theme-color-2;
$input-placeholder: lighten($almost-black, 30%);
$input-label-color: $gray;
