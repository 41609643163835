/**
 * Form
 *
 * @since 1.0.0
 */

/*
 * NOTE <input>:
 * Make min-height and line-height the same for cross-browser height;
 * Firefox/Safari/Chrome needs MIN-height for <select>;
 * Edge needs height;
 */

.hf-warning {
  display: none;
}

form {
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6,
//   p {
//     width: 100%;
//   }

  input[type='text'],
  input[type='email'],
//   input[type='number'],
//   input[type='tel'],
//   input[type='url'],
  input[type='search'],
  input[type='submit'],
  input[type='password'],
  select,
  textarea {
    @include transition(all);
    z-index: 1;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    font-family: $primary-font-family;
    font-size: rem-calc(16);
    background-color: $white;
    color: $input-color;
    outline: 0;
    border: 1px solid $light-blue-gray-alt;
    border-radius: rem-calc(300);
    box-shadow: none;
    padding: 0 2rem;
    margin: 0;
    margin-top: rem-calc(10);
    min-height: 3.5rem;
    line-height: 3.5rem;

    @supports (-ms-ime-align: auto) {
      /* Edge */
      height: 3.5rem;
    }

    // &:focus {
    //   border-color: $input-focus;
    // }

    &:disabled {
      background-color: $input-disabled;
    }

    &::placeholder {
      color: $input-placeholder;
      opacity: 1;
    }

    &::-ms-clear {
      display: none;
    }
  }

  input[type='submit'] {
    border: none;
    border-radius: rem-calc(300);
    color: $white;
    font-size: rem-calc(18);
    font-weight: 600;
    background-image: $gradient-button;
    min-height: 3.5rem;
    line-height: 3.5rem;
  }

  input[type='search'] {
    min-height: 3rem;
    line-height: 3rem;
  }
  
  label {
    font-size: rem-calc(16);
    font-weight: 500;
  }

//   textarea {
//     @include transition();
//     display: block; //removes 4px whitespace
//     resize: vertical;
//     line-height: 1.2;
//     padding-top: 1rem;
//     padding-bottom: 1rem;
//     padding-right: 2rem; //room for c-input__status
//     min-height: rem-calc(100);

//     &:focus {
//       min-height: rem-calc(200);
//     }
//   }

//   select {
//     color: $input-placeholder;
//     appearance: none;
//     background-image: url('../../icons/chevron-down.svg');
//     background-position: calc(100% - 14px) center;
//     background-repeat: no-repeat;
//     background-size: 20px 20px;

//     &:disabled {
//       background-color: $input-disabled;
//     }

//     &::-ms-expand {
//       display: none;
//     }

//     &::-ms-value {
//       background: none;
//       color: $input-placeholder;
//     }
//   }

//   input[type='search'] {
//     background-image: url('../../icons/search.svg');
//     background-position: left center;
//     background-repeat: no-repeat;
//     background-size: 20px 20px;
//     padding-left: 1.75rem;

//     &::-webkit-search-decoration {
//       -webkit-appearance: none; //safari: remove left-padding
//     }
//   }
}

