.v-install {
  min-height: 100vh;
  position: relative;

  &__background-wrapper {
    min-height: 100vh;
    background-image: url('../../images/Install-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__content-wrapper {
    margin: 160px 0 257px 0px;
    background-color: $white;
    height: 441px;
    width: 585px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__content-container {
    padding: 70px 40px 70px 40px;
    justify-content: center;
  }

  &__content-title {
    font-size: 30px;
    font-weight: 600;
  }

  &__content-text {
    font-weight: 300;
    text-align: center;
    margin-top: rem-calc(18);
    margin-bottom: rem-calc(42);
  }

  &__logo-row {
    margin-bottom: rem-calc(24);
  }

  &__logo-row__logo {
    height: rem-calc(40);
  }

  &__logo-row__icon {
    color: $almost-black;
    height: 22px;
    margin: 0px 20px 0px 20px;
  }

}
