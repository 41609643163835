.u-font-size {
  &--l,
  &--l p {
    font-size: rem-calc(18);

    @include breakpoint(large) {
      font-size: rem-calc(20);
    }
  }

  &--s,
  &--s p {
    font-size: rem-calc(14);

    @include breakpoint(large) {
      font-size: rem-calc(16);
    }
  }
}
