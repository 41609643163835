/* scss-lint:disable SelectorFormat */
/**
* Enabled sizes
*/
$include-1-1-up: true;
$include-1-1-only: true;
$include-1-2-up: true;
$include-1-2-only: true;
$include-1-3-up: true;
$include-1-3-only: true;
$include-2-3-up: true;
$include-2-3-only: true;
$include-1-4-up: true;
$include-1-4-only: true;
$include-3-4-up: true;
$include-3-4-only: true;
$include-1-5-up: true;
$include-1-5-only: true;
$include-4-5-up: true;
$include-4-5-only: true;
$include-1-6-up: true;
$include-1-6-only: true;
$include-5-6-up: true;
$include-5-6-only: true;

$margin: (
  50: 0.5rem,
  //8px
    33: 0.625rem,
  //10px
    25: 0.75rem,
  //12px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

$breakpoints-up: (
  s: 'small',
  m: 'medium',
  l: 'large',
  xl: 'xlarge',
  xm: 'xmlarge',
  xxl: 'xxlarge',
);

$breakpoints-only: (
  s-o: 'small',
  m-o: 'medium',
  l-o: 'large',
  xl-o: 'xlarge',
  xm-o: 'xmlarge',
  xxl-o: 'xxlarge',
);

$gap: (
  none: 0,
  s: 0.625rem,
  m: 1.5625rem,
  l: 3.125rem,
  xl: 4.6875rem,
);

/**
* 1-2
*/
@if $include-1-1-up {
  .g-1\/1 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/**
* 1-2
*/
@if $include-1-1-only {
  .g-1\/1 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/**
* 1-2
*/
@if $include-1-2-up {
  .g-1\/2 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(50% - calc(#{map-get($gap, $mkey)} / 2));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(2n + 2),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@if $include-1-2-only {
  .g-1\/2 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(50% - calc(#{map-get($gap, $mkey)} / 2));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(2n + 2),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/**
* 1-3
*/
@if $include-1-3-up {
  .g-1\/3 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc((100% / 3) - calc(#{map-get($gap, $mkey)} / 3 * 2));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(3n + 3),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@if $include-1-3-only {
  .g-1\/3 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc((100% / 3) - calc(#{map-get($gap, $mkey)} / 3 * 2));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(3n + 3),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/**
* 2-3
*/
@if $include-2-3-up {
  .g-2\/3 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(((100% / 3) * 2) - calc(#{map-get($gap, $mkey)} / 3 * 2));
            }
          }
        }
      }
    }
  }
}

@if $include-2-3-only {
  .g-2\/3 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(((100% / 3) * 2) - calc(#{map-get($gap, $mkey)} / 3 * 2));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(3n + 3),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/**
* 1-4
*/
@if $include-1-4-up {
  .g-1\/4 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(25% - calc(#{map-get($gap, $mkey)} / 4 * 3));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(4n + 4),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@if $include-1-4-only {
  .g-1\/4 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(25% - calc(#{map-get($gap, $mkey)} / 4 * 3));

              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(4n + 4),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/**
* 3-4
*/
@if $include-3-4-up {
  .g-3\/4 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(75% - calc(#{map-get($gap, $mkey)} / 4 * 3));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(4n + 4),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@if $include-3-4-only {
  .g-3\/4 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(75% - calc(#{map-get($gap, $mkey)} / 4 * 3));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(4n + 4),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/**
* 1-5
*/
@if $include-1-5-up {
  .g-1\/5 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(20% - calc(#{map-get($gap, $mkey)} / 5 * 4));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(5n + 5),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@if $include-1-5-only {
  .g-1\/5 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(20% - calc(#{map-get($gap, $mkey)} / 5 * 4));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(5n + 5),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/**
* 4-5
*/
@if $include-4-5-up {
  .g-4\/5 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(80% - calc(#{map-get($gap, $mkey)} / 5 * 4));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(5n + 5),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@if $include-4-5-only {
  .g-4\/5 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc(80% - calc(#{map-get($gap, $mkey)} / 5 * 4));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(5n + 5),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/**
* 1-6
*/
@if $include-1-6-up {
  .g-1\/6 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc((100% / 6) - calc(#{map-get($gap, $mkey)} / 6 * 5));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(6n + 6),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@if $include-1-6-only {
  .g-1\/6 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc((100% / 6) - calc(#{map-get($gap, $mkey)} / 6 * 5));

              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(6n + 6),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/**
* 5-6
*/
@if $include-5-6-up {
  .g-5\/6 {
    @each $key, $bp in $breakpoints-up {
      &\@#{$key} {
        @include breakpoint($bp) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc((100% / 6 * 5) - calc(#{map-get($gap, $mkey)} / 6 * 5));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(6n + 6),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@if $include-5-6-only {
  .g-5\/6 {
    @each $key, $bp in $breakpoints-only {
      &\@#{$key} {
        @include breakpoint($bp only) {
          @each $mkey, $margin in $gap {
            &\--#{$mkey} {
              width: calc((100% / 6 * 5) - calc(#{map-get($gap, $mkey)} / 6 * 5));
              margin-right: #{map-get($gap, $mkey)};

              &:nth-child(6n + 6),
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}