.u-image {
  position: relative;
  display: block;
  width: 100%;

  &::before {
    // reminder: give padding-top
    content: '';
    display: block;
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;'; // ie11 polyfill

    &--contain {
      object-fit: contain;
      object-position: center center;
      font-family: 'object-fit: contain; object-position: center center;';
    }
  }

  &--square::before {
    padding-top: 100%; // 1:1
  }
  &--v_xxlarge::before {
    padding-top: 213%; //
  }
  &--v_large::before {
    padding-top: 125%; // 4:5
  }
  &--v_medium::before {
    padding-top: 150%; // 2:3
  }
  &--v_small::before {
    padding-top: 177.8%; // 16:9
  }
  &--h_xxlarge::before {
    padding-top: 88%; // 5:4
  }
  &--h_xlarge::before {
    padding-top: 80%; // 5:4
  }
  &--h_large::before {
    padding-top: 75%; // 4:3
  }
  &--h_medium::before {
    padding-top: 66.7%; // 3:2
  }
  &--h_small::before {
    padding-top: 56.3%; // 16:9
  }
  &--h_xsmall::before {
    padding-top: 42.5%; // 2.35:1
  }
  &--h_xxsmall::before {
    padding-top: 25%; // 4:1
  }

  @include breakpoint(medium only) {
    &--square-t-t::before {
      padding-top: 100%; // 1:1
    }
    &--v_xxlarge-t::before {
      padding-top: 213%; //
    }
    &--v_large-t::before {
      padding-top: 125%; // 5:4
    }
    &--v_medium-t::before {
      padding-top: 150%; // 3:2
    }
    &--v_small-t::before {
      padding-top: 177.8%; // 16:9
    }
    &--h_xxlarge-t::before {
      padding-top: 88%; // 4:5
    }
    &--h_xlarge-t::before {
      padding-top: 80%; // 4:5
    }
    &--h_large-t::before {
      padding-top: 75%; // 4:3
    }
    &--h_medium-t::before {
      padding-top: 66.7%; // 2:3
    }
    &--h_small-t::before {
      padding-top: 56.3%; // 9:16
    }
    &--h_xsmall-t::before {
      padding-top: 42.5%; // 2.35:1
    }
    &--h_xxsmall-t::before {
      padding-top: 25%; // 4:1
    }
  }

  @include breakpoint(small only) {
    &--square-m::before {
      padding-top: 100%; // 1:1
    }
    &--v_xxlarge-m::before {
      padding-top: 213%; //
    }
    &--v_large-m::before {
      padding-top: 125%; // 5:4
    }
    &--v_medium-m::before {
      padding-top: 150%; // 3:2
    }
    &--v_small-m::before {
      padding-top: 177.8%; // 16:9
    }
    &--h_xxlarge-m::before {
      padding-top: 88%; // 4:5
    }
    &--h_xlarge-m::before {
      padding-top: 80%; // 4:5
    }
    &--h_large-m::before {
      padding-top: 75%; // 4:3
    }
    &--h_medium-m::before {
      padding-top: 66.7%; // 2:3
    }
    &--h_small-m::before {
      padding-top: 56.3%; // 9:16
    }
    &--h_xsmall-m::before {
      padding-top: 42.5%; // 2.35:1
    }
    &--h_xxsmall-m::before {
      padding-top: 25%; // 4:1
    }
  }
}
