/**
 * Icon component
 *
 * @since 1.0.0
 */

.c-icon {
  vertical-align: middle;
  // fill: inherit;
  stroke: inherit;
  // margin-top: 2px;

  &--xsmall {
    @include square(0.75rem);
  }
  &--small {
    @include square(1rem);
  }
  &--medium {
    @include square(1.25rem);
  }
  &--large {
    @include square(1.5rem);
  }
  &--xlarge {
    @include square(1.75rem);
  }

  &--spinner {
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-0.5rem);
  }
  60% {
    transform: translateY(-0.25rem);
  }
}
