.c-default-layout {
    background-color: #EFF3F8;

    &__sidebar {
        width: rem-calc(250);
    }

    &__container {
        padding: 3.5rem 0 6.5rem 0;
        min-height: calc(100vh - 251px);
    }

    &__content-wrapper {
        background-color: $white;
        width: calc(100% - 250px);
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    }

    &__content-container {
        &__padding--default {
            padding: 86px 83px 88px 83px;  
        }
    }
}