.c-sidebar {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &__items-container {
        display: flex;
        flex-direction: column;
    }

    &__item-container {
        padding: 12px;

        &--active {
            background-color: #E3EAF4;
        }
    }

    &__item {
        &-title {
            font-size: rem-calc(16);
        }

        &-icon {
            max-width: rem-calc(18);
            margin-right: rem-calc(13);
            margin-bottom: rem-calc(1);
        }
    }

    &__category {
        margin-left: 13px;

        &-title {
            font-size: rem-calc(14);
            letter-spacing: rem-calc(1);
            text-transform: uppercase;
            color: $text-gray;
        }
    }

    &__group {
        padding-right: 34px;
        &--last {
            margin-top: auto;
        }
    }
}
