/**
 * Fonts
 *
 * @since 1.0.0
 */

$pre-font-load-font-family: sans-serif;
$heading-font-family: 'Campton', sans-serif;
$primary-font-family: 'Campton', sans-serif;
$secondary-font-family: 'P22 Mackinac', serif;
