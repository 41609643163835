// scss-lint:disable ImportantRule

.u-pad-t {
  &--none {
    @include breakpoint(medium only) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  &--top-none {
    @include breakpoint(medium only) {
      padding-top: 0 !important;
    }
  }
  &--bot-none {
    @include breakpoint(medium only) {
      padding-bottom: 0 !important;
    }
  }

  /**
  * Padding xxxlarge
  */
  &--xxxxl {
    @include breakpoint(medium only) {
      padding-top: 9.375rem !important;
      padding-bottom: 9.375rem !important;
    }
  }
  &--top-xxxxl {
    @include breakpoint(medium only) {
      padding-top: 9.375rem !important;
    }
  }
  &--bot-xxxxl {
    @include breakpoint(medium only) {
      padding-bottom: 9.375rem !important;
    }
  }

  /**
  * Padding xxxlarge
  */
  &--xxxl {
    @include breakpoint(medium only) {
      padding-top: 7.8125rem !important;
      padding-bottom: 7.8125rem !important;
    }
  }
  &--top-xxxl {
    @include breakpoint(medium only) {
      padding-top: 7.8125rem !important;
    }
  }
  &--bot-xxxl {
    @include breakpoint(medium only) {
      padding-bottom: 7.8125rem !important;
    }
  }

  /**
  * Padding xxlarge
  */
  &--xxl {
    @include breakpoint(medium only) {
      padding-top: 6.25rem !important;
      padding-bottom: 6.25rem !important;
    }
  }
  &--top-xxl {
    @include breakpoint(medium only) {
      padding-top: 6.25rem !important;
    }
  }
  &--bot-xxl {
    @include breakpoint(medium only) {
      padding-bottom: 6.25rem !important;
    }
  }

  /**
  * Padding xlarge
  */
  &--xl {
    @include breakpoint(medium only) {
      padding-top: 4.6875rem !important;
      padding-bottom: 4.6875rem !important;
    }
  }
  &--top-xl {
    @include breakpoint(medium only) {
      padding-top: 4.6875rem !important;
    }
  }
  &--bot-xl {
    @include breakpoint(medium only) {
      padding-bottom: 4.6875rem !important;
    }
  }

  /**
  * Padding large
  */
  &--l {
    @include breakpoint(medium only) {
      padding-top: 3.125rem !important;
      padding-bottom: 3.125rem !important;
    }
  }
  &--top-l {
    @include breakpoint(medium only) {
      padding-top: 3.125rem !important;
    }
  }
  &--bot-l {
    @include breakpoint(medium only) {
      padding-bottom: 3.125rem !important;
    }
  }

  /**
   * Padding medium
   */
  &--m {
    @include breakpoint(medium only) {
      padding-top: 1.5625rem !important;
      padding-bottom: 1.5625rem !important;
    }
  }
  &--top-m {
    @include breakpoint(medium only) {
      padding-top: 1.5625rem !important;
    }
  }
  &--bot-m {
    @include breakpoint(medium only) {
      padding-bottom: 1.5625rem !important;
    }
  }

  /**
   * Padding small
   */
  &--s {
    @include breakpoint(medium only) {
      padding-top: 0.625rem !important;
      padding-bottom: 0.625rem !important;
    }
  }
  &--top-s {
    @include breakpoint(medium only) {
      padding-top: 0.625rem !important;
    }
  }

  &--bot-s {
    @include breakpoint(medium only) {
      padding-bottom: 0.625rem !important;
    }
  }

  /**
   * Padding xsmall
   */
  &--xs {
    @include breakpoint(medium only) {
      padding-top: 0.3125rem !important;
      padding-bottom: 0.3125rem !important;
    }
  }
  &--top-xs {
    @include breakpoint(medium only) {
      padding-top: 0.3125rem !important;
    }
  }

  &--bot-xs {
    @include breakpoint(medium only) {
      padding-bottom: 0.3125rem !important;
    }
  }
}
