.v-register {
  min-height: 100vh;

  &-header {
    padding-bottom: rem-calc(55);
  }

  &-content {
    &__wrapper {
      padding-bottom: 50px;
    }

    &__container {
      width: 480px;
    }

    &__return-icon {
      max-width: 16px;
      margin-right: rem-calc(12);
    }
  
    &__review {
      width: 100%;
      max-width: rem-calc(110);
      margin-left: auto;
    }
  
    &__h1-small {
      font-size: rem-calc(30);
      font-weight: 600;
      margin-bottom: rem-calc(39);
        
      // @include breakpoint(large) {
      //   font-size: rem-calc(30);
      // }
    }
  
    &__span {
      display: block;
    }
  
    &__inner-content {
      max-width: rem-calc(481);
    }
  
    &__remember-me {
      gap: rem-calc(9);
      margin-bottom: rem-calc(24);
    }
  
    &__p-small {
      font-size: rem-calc(14);
      padding-bottom: 23px;
  
      &--underline {
        font-size: rem-calc(14);
        text-decoration: underline;
      }
    }

    &__no-account {
      margin-top: rem-calc(18);

      &__title {
        color: $disclaimer;
        margin-top: 0;
        margin-bottom: 0px;
        font-size: rem-calc(16);
        font-weight: 300;

        &-secondary {
          font-size: rem-calc(16);
          margin-top: 0;
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }

    &__sign-up--underline {
      text-decoration: underline;
    }
  }

  &-form__name-container {
    display: flex;
  }

  &-form__name-row {
    flex-direction: column;
  }

  &-form__lastname-row {
    flex-direction: column;
  }
}