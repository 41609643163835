.u-gallery-col-margin {
  &--none {
    margin-bottom: 0;
  }

  &--s {
    margin-bottom: 0.625rem;
  }

  &--m {
    margin-bottom: 1.5625rem;
  }

  &--l {
    margin-bottom: 3.125rem;
  }

  &--xl {
    margin-bottom: 4.6875rem;
  }
}

.u-gallery-col-margin-t {
  @include breakpoint(medium only) {
    &--none {
      margin-bottom: 0;
    }

    &--s {
      margin-bottom: 0.625rem;
    }

    &--m {
      margin-bottom: 1.5625rem;
    }

    &--l {
      margin-bottom: 3.125rem;
    }

    &--xl {
      margin-bottom: 4.6875rem;
    }
  }
}

.u-gallery-col-margin-s {
  @include breakpoint(small only) {
    &--none {
      margin-bottom: 0;
    }

    &--s {
      margin-bottom: 0.625rem;
    }

    &--m {
      margin-bottom: 1.5625rem;
    }

    &--l {
      margin-bottom: 3.125rem;
    }

    &--xl {
      margin-bottom: 4.6875rem;
    }
  }
}
