/**
 * Shadow
 *
 * @since 1.0.0
 */

$box-shadow-1: 0 2px 50px rgba($theme-color-3, 0.2);
$box-shadow-2: 2px 10px 20px rgba($theme-color-3, 0.2);
$box-shadow-3: 0 2px 10px rgba($theme-color-1, 0.15);
$box-shadow-4: 0 2px 50px -2px rgba($theme-color-3, 0.2);
$text-shadow: 0 0 40px rgba($theme-color-4, 0.43);
$header-shadow: 0 0 1px rgba(#12203B, 0.9);
