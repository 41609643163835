.v-dashboard-overview {
    &__content-wrapper {
        width: 100%;
    }

    &__content-container {
        width: rem-calc(600);
        justify-content: center;
        margin: 0 auto;
    }

    &__content-title {
        font-size: rem-calc(46);
        font-weight: 500;
        text-align: center;
        margin-bottom: rem-calc(6);
        margin-top: rem-calc(0);
    }

    &__content-text {
        width: 100%;
        text-align: center;
        margin-bottom: rem-calc(30);
    }

    &__cards-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: rem-calc(75);
      margin-bottom: rem-calc(21);
    }
}