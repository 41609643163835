/**
 * Fonts
 *
 * @since 1.0.0
 */

@import url('https://use.typekit.net/ika7dvh.css');

@font-face {
  font-family: 'Campton';
  src: local('Campton'), url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Thin.eot') format('eot'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Thin.woff') format('woff'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Campton';
  src: local('Campton'), url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-ExtraLight.eot') format('eot'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-ExtraLight.woff') format('woff'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Campton';
  src: local('Campton'), url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Light.eot') format('eot'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Light.woff') format('woff'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Campton';
  src: local('Campton'), url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Book.eot') format('eot'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Book.woff') format('woff'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Campton';
  src: local('Campton'), url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Medium.eot') format('eot'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Medium.woff') format('woff'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Campton';
  src: local('Campton'), url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-SemiBold.eot') format('eot'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-SemiBold.woff') format('woff'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Campton';
  src: local('Campton'), url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Book-Bold.eot') format('eot'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Book-Bold.woff') format('woff'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Book-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Campton';
  src: local('Campton'), url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-ExtraBold.eot') format('eot'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-ExtraBold.woff') format('woff'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Campton-black';
  src: local('Campton-black'), url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Black.eot') format('eot'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Black.woff') format('woff'),
    url('https://cdn2.hubspot.net/hubfs/248909/Campton/Campton-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
