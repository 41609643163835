.c-activation-bar {
    &__button-container {
        margin-top: 16px;
    }
    
    &__input {
        width: 380px;
        display: block;
    }

    &__button {
        padding: 30rem;
    }
}