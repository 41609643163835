.c-modal {
    &__content-container {
        padding: 20px;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    &__title {
        font-weight: 500;
        font-size: 26px;
    }

    &__content {
        width: 500px;
        font-weight: 300px;
    }

    &__button-container {
    }

    &__button-confirm {
        margin-top: 5px;
        margin-right: 15px;
    }

    &__button-cancel {
    }
}
