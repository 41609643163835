.u-text-color {
  &--white {
    color: $white;
  }
  &--gray {
    color: $gray;
  }
  &--black {
    color: $black;
  }
  &--almost-black {
    color: $almost-black;
  }
  &--almost-black-gray {
    color: $almost-black;

    p,
    a,
    li {
      color: $gray;
    }
  }
  &--dark-gray {
    color: $dark-gray;
  }
  &--light-gray {
    color: $light-gray;
  }
  &--theme-color-1 {
    color: $theme-color-1;
  }
  &--theme-color-2 {
    color: $theme-color-2;
  }
  &--theme-color-3 {
    color: $theme-color-3;
  }
  &--theme-color-4 {
    color: $theme-color-4;
  }
  &--theme-color-5 {
    color: $theme-color-5;
  }
}
