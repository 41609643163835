.u-bg-color {
  &--none {
    background-color: transparent;
  }
  &--white {
    background-color: $white;
  }
  &--light-gray {
    background-color: $light-gray;
  }
  &--gray {
    background-color: $gray;
  }
  &--light-blue {
    background-color: $light-blue;
  }
  &--light-blue-alt {
    background-color: $light-blue-gray-alt;
  }
  &--black {
    background-color: $black;
  }
  &--almost-black {
    background-color: $almost-black;
  }
}