.v-search-results {
    &__content-wrapper {
        padding: 76px 55px;
    }

    &__content-container {
        flex-direction: column;
    }

    &__title {
        font-size: rem-calc(30);
        font-weight: 500;
        margin-bottom: rem-calc(6);
        margin-top: rem-calc(60);
    }

    &__text {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: rem-calc(36);
    }
}