.c-search-row {
    &__content-container {
        width: 100%;
        align-items: center;
    }

    &__line {
        width: 100%;
        height: 1px;
        background-color: $light-blue-gray-alt;
        margin-top: rem-calc(24);
        margin-bottom: rem-calc(24);
    }

    &__name-container {
        width: 25%
    }

    &__address-container {
        width: 25%
    }

    &__info-container {
        margin-left: auto;
        width: 25%
    }

    &__name {
        font-size: 16px;
        font-weight: 600;
        margin-right: 48px;
    }

    &__address {
        font-size: 16px;
        font-weight: 300;
    }

    &__location {
        font-size: 16px;
        font-weight: 300;
    }

    &__dossier-title {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
    }

    &__dossier-value {
        font-size: 16px;
        font-weight: 300;
    }

    &__establishment-title {
        font-size: 16px;
        font-weight: 500;
    }

    &__establishment-value {
        font-size: 16px;
        font-weight: 300;
    }

    &__button-container {
        margin-left: auto;
    }
}