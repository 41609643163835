@keyframes run {
  0% { 
    transform: translateX(0); 
  }
  50% { 
    transform: translateX(.25rem);
  }
  100% { 
    transform: translateX(0);
  }
}